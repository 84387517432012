function init() {
    $('.SL_Blockpromo-slideContainer').slick({
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    })
}  

export default { init }