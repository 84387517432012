function init() {
  let installer = document.querySelector('.SL_Sectionhead')

  if (!installer) {
    return
  } else {
    let phoneButton = installer.querySelector('[data-slphonebutton]')

    phoneButton.addEventListener('click', showPhoneNumber)

    let devisButton = installer.querySelector('.SL_Sectionhead-content-button--devis')

    devisButton.addEventListener('click', sendPushDatalayer)

    function showPhoneNumber(event) {
      event.preventDefault();
      fetch(Routing.generate('thermor_storelocator_company_registerphoneclick', {companyId: installer.dataset.slcompanyId}))

      phoneButton.textContent = phoneButton.dataset.slphonebutton

      dataLayer.push({
        'environment': installer.dataset.env, // Environnement de la page en cours (prod / preprod / etc)
        'pageType': 'trouver un installateur',
        'event': 'afficherNum'
      });
      phoneButton.removeEventListener('click', showPhoneNumber)
    }

    function sendPushDatalayer(event) {
      event.preventDefault();
      dataLayer.push({
        'environment': installer.dataset.env, // Environnement de la page en cours (prod / preprod / etc)
        'pageType': 'trouver un installateur',
        'event': 'demandeDevis'
      });
      window.location.href = event.target.getAttribute('href');
    }
  }
}

export default { init }