function init() {
  let card = document.querySelectorAll('.SL_Card')
  let form = document.querySelector('.SL_Searchform')

  if (!card) {
    return
  } else {
    let buttonsAddCompany = document.querySelectorAll('[data-sladd-company]');
    
    let phoneButtons = document.querySelectorAll('[data-slphonebutton]');    

    if (window.innerWidth >= 1200) {
      const buttonToMap = document.querySelectorAll('[data-slcompanybutton-lat]');
  
      buttonToMap.forEach((button) => {
        button.addEventListener('click', () => {

          const buttonToMapClick = new CustomEvent("buttonToMapClick", {detail: 
            {
              lat: parseFloat(button.dataset.slcompanybuttonLat),
              lng: parseFloat(button.dataset.slcompanybuttonLng)
            }
          });
          document.dispatchEvent(buttonToMapClick);
        })
      })
    }

    // create CustomEvent for each devis button of the card list
    buttonsAddCompany.forEach(button => {
      button.addEventListener("click", () => {
        const buttonQuoteClick = new CustomEvent ("buttonQuoteClick", {detail:
          {
            companyId: button.dataset.sladdCompany,
          }
        });
        dataLayer.push({
          'environment': form.dataset.env, // Environnement de la page en cours (prod / preprod / etc)
          'pageType': 'trouver un installateur',
          'event': 'demandeDevis'
        });
        document.dispatchEvent(buttonQuoteClick);
      })
    })

    // listen CustomEvent from managing state in selectaccordion to remove style to button which is not in company selected session
    document.addEventListener("companySelectedRemoveEvent", (e) => {
      document.querySelector(`[data-sladd-company="${e.detail.companyId}"]`).classList.remove('SL_Card-button--removeDevis');
    })
    
    // listen CustomEvent from managing state in selectaccordion to add style to button which is in company selected session
    document.addEventListener("companySelectedAddEvent", (e) => {
      document.querySelector(`[data-sladd-company="${e.detail.companyId}"]`).classList.add('SL_Card-button--removeDevis');
    })

    document.addEventListener("setErrorMessage", (e) => {
      const button = document.querySelector(`[data-sladd-company="${e.detail.companyId}"]`);
      button.insertAdjacentHTML('beforeend', e.detail.errorMessage);
          
      const buttonErrorMessage = button.querySelector('[data-slcarderror]'); 
      
      setTimeout(() => {
        buttonErrorMessage.remove();
      }, 1000);
    })
    
    // listen CustomEvent from map component on marker click
    document.addEventListener("markerClick", () => {
      phoneButtons = document.querySelectorAll('[data-slphonebutton]');

      cardButtonClick();
    })

    cardButtonClick();

    // listen customEvent from selectaccordion removebutton to remove style to buttons that are in company selected session
    document.addEventListener("removeDetailsButton", (e) => {
      buttonsAddCompany.forEach(button => {
        if (button.dataset.sladdCompany == e.detail.companyId) {
          button.classList.remove('SL_Card-button--removeDevis');
        }
      });
    })

    function cardButtonClick() {
      
      phoneButtons.forEach(button => {
        if (button === null) {
          return
        }
  
        button.addEventListener('click', getPhoneNumber)
    
        function getPhoneNumber (event) {
          event.preventDefault();
          if(button.dataset.slcompanyId){
            fetch(Routing.generate('thermor_storelocator_company_registerphoneclick', {companyId: button.dataset.slcompanyId}))
          }
      
          button.textContent = button.dataset.slphonebutton

          dataLayer.push({
            'environment': button.dataset.env, // Environnement de la page en cours (prod / preprod / etc)
            'pageType': 'trouver un installateur',
            'event': 'afficherNum'
          });

          button.removeEventListener('click', getPhoneNumber)
        }
      });
    }
  }
}
 
export default { init }