function init() {
  let form = document.querySelector('.SL_Searchform');

  if (!form) {
    return
  } else {
    let autocompleteInput = document.querySelector("#SL_autocomplete");
    let selectFamily = document.querySelector("#SL_family");
    let buttonGeoloc = document.querySelector("#SL_geolocation-button");
    let env = form.dataset.env;
    let postale_code = null;

    let searchFormButton = document.querySelector("[data-slsearchform-button]");

    if (window.location.search) {
      searchFormButton.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    buttonGeoloc.addEventListener("click", function() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function(position) {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;
          fetch(Routing.generate('storelocator_geolocation_get_address', {latitude: latitude, longitude: longitude}))
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            let address = data.address;
            autocompleteInput.value = address;
            postale_code = data.postale_code;
          })
          .catch(error => console.error(error));
        });
      } else {
        console.log("La géolocalisation n'est pas supportée par ce navigateur.");
      }
    });
  
    if (selectFamily.value !== "") {
      selectFamily.classList.remove('SL_Searchform-placeholder')
    }
    
    selectFamily.addEventListener('change', function() {
      if (selectFamily.value !== "") {
        selectFamily.classList.remove('SL_Searchform-placeholder')
      }
    });
  
    form.addEventListener('submit', () => {
      dataLayer.push({
        'environment': env, // Environnement de la page en cours (prod / preprod / etc)
        'pageType': 'trouver un installateur',
        'codePostal': postale_code, // code postal recherché
        'familleProduit': selectFamily.selectedOptions[0].text, // famille produit recherché
        'event': 'recherche'
      });
    })
  }   
}  

export default { init }