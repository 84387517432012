function init() {
  const listButton = document.querySelector('[data-slselectdisplay-list]')

  if (!listButton) {
    return
  } else {
    const mapButton = document.querySelector('[data-slselectdisplay-map]')
    const list = document.querySelector('[data-slselect-list]')
    const map = document.querySelector('[data-slselect-map]')
    const details = document.querySelector('[data-slselected-details]')

    if (window.innerWidth < 1200 ) {
      details.classList.add('SL_Selectedaccordion-listMobile')
    }

    listButton.addEventListener('click', () => {
      list.classList.remove('mq--onlyDesktop')
      map.classList.add('mq--onlyDesktop')
      details.classList.add('SL_Selectedaccordion-listMobile')
    })
    
    mapButton.addEventListener('click', () => {
      map.classList.remove('mq--onlyDesktop')
      list.classList.add('mq--onlyDesktop')
      details.classList.remove('SL_Selectedaccordion-listMobile')
    })
  }
} 
 
export default { init }