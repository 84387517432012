// ------
// Styles
// ------

import "normalize.css/normalize.css";
import "./styles/index.scss";

// ---------
// SVG icons
// ---------

import "./icons";

// -------
// Scripts
// -------

import card from "./components/card/card";
import searchform from "./components/searchform/searchform";
import sectionhead from "./components/sectionhead/sectionhead";
import map from "./components/map/map";
import mapinfo from "./components/mapinfo/mapinfo";
import formquote from "./components/formquote/formquote";
import selectdisplay from "./components/selectdisplay/selectdisplay";
import selectedaccordion from "./components/selectedaccordion/selectedaccordion";
import territorysearchform from "./components/territorysearchform/territorysearchform";
import blockpromo from "./components/blockpromo/blockpromo";
import jQuery from "./components/blockpromo/jquery.min.js";
import  slick from "./components/blockpromo/slick.min.js";

// Run init function for all files when document is ready
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init);
} else {
  init();
}

function init() {
  card.init()
  searchform.init()
  sectionhead.init()
  map.init()
  mapinfo.init()
  formquote.init()
  selectdisplay.init()
  selectedaccordion.init()
  territorysearchform.init()
  jQuery
  blockpromo.init()
  slick
}
