
function init() {
  const territorySearchForm = document.querySelector('.SL_Territorysearchform');

  if (!territorySearchForm) {
    return;
  }

  let territorySearchFormButton = document.querySelector("[data-slterritorysearchform-button]");
  
  const urlParams = new URLSearchParams(window.location.search);
  
  const selectFamily = territorySearchForm.querySelector('#SL_family');
  
  if (window.location.search) {
    
    if (window.innerWidth < 1200) {
      territorySearchFormButton.scrollIntoView({behavior: 'smooth', block: 'start'});
    } else {
      window.scrollTo({top: 500, behavior: "smooth"});
    }
  }
  
  selectFamily.addEventListener('change', () => {
    if (selectFamily.value === '') {
      urlParams.delete('family');
    } else {
      urlParams.set('family', selectFamily.value);
    }

    if (urlParams.toString() === '') {
      window.location.href = window.location.pathname;
    } else {
      window.location.href = window.location.pathname + '?' + urlParams.toString();
    }
  })

} 
 
export default { init }