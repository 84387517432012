function init() {
  // Initialize and add the map
  function initMapinfo() {
    const installer = document.querySelector('[data-map]')

    // The location of installer
    const location = {
      lat: parseFloat(installer.dataset.lat),
      lng: parseFloat(installer.dataset.lng)
    }

    // The map, centered at location
    const map = new google.maps.Map(document.querySelector('[data-map]'), {
      zoom: 18,
      center: location,
      disableDefaultUI: true,
    });
    // The marker, positioned at location
    const marker = new google.maps.Marker({
      position: location,
      map: map,
      icon: {
        path: 'M24.0024 0C19.2279 0 14.6489 1.89668 11.2728 5.27278C7.89667 8.64888 6 13.2279 6 18.0024C6 27.9506 24.0024 48 24.0024 48C24.0024 48 42.0048 27.9506 42.0048 18.0024C42.0048 13.2279 40.1081 8.64888 36.732 5.27278C33.3559 1.89668 28.7769 0 24.0024 0Z' + 'M28.2245 10.9471C28.0449 13.1283 26.2235 14.8485 24.0047 14.8485C21.668 14.8485 19.7649 12.9388 19.7649 10.5936V10.59C19.7667 8.24298 21.668 6.33331 24.0047 6.33331C26.2198 6.33331 28.0413 8.05347 28.2245 10.2365H30.075C30.271 10.2365 30.4288 10.395 30.4288 10.5918C30.4288 10.7886 30.271 10.9471 30.075 10.9471H28.2245ZM20.4906 10.9471C20.6665 12.7365 22.176 14.1378 24.0047 14.1378H24.0065C25.8352 14.1378 27.341 12.7365 27.5188 10.9471H20.4906ZM27.517 10.2365C27.3374 8.44707 25.8316 7.0458 24.0047 7.0458H24.0029C22.176 7.0458 20.6683 8.44707 20.4887 10.2365H27.517ZM21.5156 16.299H26.4865V16.3008C29.6142 16.3063 32.1614 18.8665 32.1668 22.0079V29.3113C32.1668 29.5081 32.009 29.6667 31.8131 29.6667H16.1873C15.9913 29.6667 15.8335 29.5081 15.8335 29.3113V22.0079C15.8389 18.8646 18.3879 16.3044 21.5156 16.299ZM28.9012 28.9542H31.4593V22.0079C31.4557 19.2582 29.2242 17.0151 26.4865 17.0115H21.5156C18.778 17.0151 16.5465 19.2564 16.541 22.0079V28.9542H19.0991V21.649C19.0991 21.4522 19.2569 21.2936 19.4528 21.2936C19.6488 21.2936 19.8066 21.4522 19.8066 21.649V28.9542H28.1937V21.649C28.1937 21.4522 28.3515 21.2936 28.5475 21.2936C28.7434 21.2936 28.9012 21.4522 28.9012 21.649V28.9542Z',
        fillColor: '#E62814',
        fillOpacity: 1,
        strokeWeight: 0.5,
        strokeColor: '#ffffff',
      }
    });
  }
  window.initMapinfo = initMapinfo;

} 
 
export default { init }