
function init()
{
  const form = document.querySelector('form[name="quote_request"]');
  if (form === null) {
    return;
  }

  let buttonsRemoveCompany = document.querySelectorAll('[data-slselected-company-id]');

  buttonsRemoveCompany.forEach(button => {
    button.addEventListener('click', () => {
      let companyId = button.dataset.slselectedCompanyId;
      removeCompanyFromSession(companyId);
      button.parentNode.remove();
    })
  })

  let installateurString = "";
  let companies = document.querySelectorAll('.SL_Formquote-company');
  companies.forEach( (element, index) => {
    installateurString = installateurString + element.innerText;
    if(index != 0 && index != companies.length - 1 ){
      installateurString = installateurString + '|';
    }
  });

  async function removeCompanyFromSession(companyId) {
    let response = await fetch(Routing.generate('thermor_storelocator_quoterequest_remove_company', {companyId: companyId}));

    if (!response.ok) {
      console.log(response.status);
    }

    let data = await response.json();

    if (data.count == 0) {
      window.location.href = Routing.generate('storelocator_search');
    }
  }

  function getText() {
    let delay = ''
    Object.values(document.getElementById('quote_request_deadline').options).forEach(function (option) {
      if(option.value == document.getElementById('quote_request_deadline').value){
        delay = option.text;
      }
    });
    return delay;
  }

  form.addEventListener('submit', (event) => {
    dataLayer.push({
      'environment': form.dataset.env, // Environnement de la page en cours (prod / preprod / etc)
      'pageType': 'trouver un installateur',
      'delais': getText(),
      'nomInstallateur': installateurString, // Installateurs sélectionnés
      'natureDemande': document.getElementById('quote_request_requestNature').value,
      'natureProjet': document.getElementById('quote_request_productNatureId').value,
      'produitDevis': document.getElementById('quote_request_productRanges').value,
      'event': 'confirmationDemandeDevis'
    });
  })
}

export default { init }
