import { async } from "regenerator-runtime";

function init() {
  const companySelectedContainer = document.querySelector('[data-slselected-container]');

  if (!companySelectedContainer) {
    return
  } else {   
    let buttonToTop = document.querySelector('.scroll-top');
    
    //Hide button scroll to top on map pages
    buttonToTop.classList.add('visuallyHidden');
    
    let countCompanySelected;

    let detailsLink = document.querySelector('[data-slselected-link]');  
    
    let detailsCompanyList = document.querySelector('[data-slselected-details]');
    
    let buttonsAddCompany = document.querySelectorAll('[data-sladd-company]');
    
    let errorMessage = `<span class="SL_Card-error" data-slcarderror>Limite atteinte</span>`;
    
    let crossIcon = document.querySelector('#SL_Selectedaccordion-crossTemplate').content.cloneNode(true).children[0].outerHTML;

    let companiesSelected;

    let scrollOnce = false;
        
    getSelectedCompanies();

    // listen map marker customEvent to update button devis style
    document.addEventListener("markerClick", (e) => {
      const checkCompanyInSelection = new CustomEvent("checkCompanyInSelection", {detail:
        {
          isInSelection: companiesSelected.some(company => company.id == e.detail.companyId)
        }
      });
      document.dispatchEvent(checkCompanyInSelection);
    })
    
    // listen CustomEvent on devis button to add/remove from company selected
    document.addEventListener("buttonQuoteClick", (e) => {manageCompanySelected(e.detail.companyId)});
      
    function manageCompanySelected(companyId) {  
      if (companiesSelected.some(company => company.id == companyId)) {

        removeCompanyFromSession(companyId);
        
        // create customEvent when company is removed from selection session
        const companySelectedRemoveEvent = new CustomEvent ("companySelectedRemoveEvent", {detail:
          {
            companyId: companyId
          }
        });
        document.dispatchEvent(companySelectedRemoveEvent);
        
      } else {
        if (countCompanySelected < 5) {
          try {
            addCompanyToSession(companyId)

            // create customEvent when company is add in selection session
            const companySelectedAddEvent = new CustomEvent ("companySelectedAddEvent", {detail:
              {
                companyId: companyId
              }
            });
            document.dispatchEvent(companySelectedAddEvent);
          } catch {
            console.log('error request')
          }
          
          
        } else {

          const setErrorMessage = new CustomEvent ("setErrorMessage", {detail:
            {
              errorMessage: errorMessage,
              companyId: companyId
            }
          });
          document.dispatchEvent(setErrorMessage);
        }
      }
    }
      
    async function removeCompanyFromSession(companyId) {
      let response = await fetch(Routing.generate('thermor_storelocator_quoterequest_remove_company', {companyId: companyId}));
      
      if (!response.ok) {
        console.log(response.status);
      }
      let data = await response.json();
      
      countCompanySelected = data.count;

      companiesSelected = data.companies;
      
      refreshCompanies(data.companies);
      updatePlacesLeft(data.count, data.max_count);
      
      if (data.count == 0) {
        detailsCompanyList.open = false;
      }
    }
    
    async function addCompanyToSession(companyId) {
      let response = await fetch(Routing.generate('thermor_storelocator_quoterequest_add_company', {companyId: companyId}));
      
      if (!response.ok) {
        console.log(response.status);
        throw new Error(response.status);
      }
      let data = await response.json();
      
      countCompanySelected = data.count;
      
      companiesSelected = data.companies;

      refreshCompanies(data.companies);
      updatePlacesLeft(data.count, data.max_count);
      
      if (window.innerWidth > 1200) {
        if (!scrollOnce) {
          detailsCompanyList.scrollIntoView({behavior: 'smooth', block: 'end'});
          setTimeout(() => {
            detailsCompanyList.open = true;
          }, 400);
        }
        scrollOnce = true;
      } else {
        detailsCompanyList.open = true;
      }
    }
  
    async function getSelectedCompanies() {
      
      let response = await fetch(Routing.generate('thermor_storelocator_quoterequest_get_selected_companies'));
      
      if (!response.ok) {
        console.log(response.status);
      } 
      let data = await response.json();  
      
      countCompanySelected = data.count;
      
      companiesSelected = data.companies;

      updateAddButton(data.companies);
      refreshCompanies(data.companies);
      updatePlacesLeft(data.count, data.max_count);
    }
  
    // add event listener on every removebutton from accordion 
    function refreshCompanies(companies) {
      companySelectedContainer.innerHTML = createRemoveButton(companies);
      
      let buttonsRemoveCompany = document.querySelectorAll('[data-slselected-company-id]')
      
      if (!buttonsRemoveCompany) {
        return
      } else {
        buttonsRemoveCompany.forEach(button => {
          button.addEventListener('click', () => {
            let companyId = button.dataset.slselectedCompanyId;
            removeCompanyFromSession(companyId);
            
            //expose listener when removeButton from accordion is clicked
            const removeDetailsButton = new CustomEvent("removeDetailsButton", {
              detail: {
                companyId: button.dataset.slselectedCompanyId
              }
            });
            document.dispatchEvent(removeDetailsButton);
          })
        })
      }
    }
  
    // update devis add button style if company is in selected session
    function updateAddButton(companies) {
      buttonsAddCompany.forEach(button => {
        if (companies.some(company => company.id == button.dataset.sladdCompany)) {
          button.classList.add('SL_Card-button--removeDevis');
        }
      })
    }
  
    function updatePlacesLeft(companyCount, maxCompanies) {
      let placesLeft = document.querySelector('[data-slselected-left]');
      
      let countplacesLeft = maxCompanies - companyCount;
      
      if (companyCount < 1) {
        detailsLink.addEventListener("click", disableLink);
      } else {
        detailsLink.removeEventListener("click", disableLink);
      }
      
      if (window.innerWidth > 1200 ) {
        placesLeft.innerText = `${countplacesLeft} restants`;
      } 
    }
  
    function disableLink(event) {
      event.preventDefault();
    }
  
    function createRemoveButton(companies) {
      return `
      ${companies ? companies.map(company => `
      <div class="SL_Selectedaccordion-company">
      ${company.name} <button data-slselected-company-id="${company.id}">${crossIcon}</button>
      </div>
      `).join(''): ''}
      `;
    }
  }
}

export default { init }